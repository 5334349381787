import React from "react";

function ThemedSuspense() {
  return (
    <div className="w-full text-center h-screen p-6 text-lg font-medium text-gray-600  ">
      Loading...
    </div>
  );
}

export default ThemedSuspense;
