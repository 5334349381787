import React, { useContext, Suspense, useEffect, lazy } from "react";
import Header from "../components/header";
import ThemedSuspense from "../components/themed_suspense";
import { CheckStatusSubscription } from "../queries";
import { useSubscription, useQuery } from "urql";
import { AuthContext } from "../context/auth_context";
import SessionNotFound from "./session_not_found";
import { useNavigate } from "react-router-dom";
const Page404 = lazy(() => import("../pages/404"));

function Layout({ children: Children }) {
  const { auth, session, setSessionData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [statusSubscription] = useSubscription({
    query: CheckStatusSubscription,
    variables: {
      id: session?.id,
    },
  });

  useEffect(() => {
    if (
      statusSubscription?.data?.user_session_by_pk?.id &&
      (statusSubscription?.data?.user_session_by_pk?.id !== session?.id ||
        statusSubscription?.data?.user_session_by_pk?.status !==
          session?.status)
    ) {
      setSessionData(statusSubscription?.data?.user_session_by_pk);
    }
  }, [statusSubscription?.data]);

  return (
    <div className={`bg-gray-50 min-h-screen   ${"overflow-hidden"}`}>
      {session?.status === "active" ? (
        <div className=" flex-1 ">
          <Header />
          <div className="mt-24 ">
            <Suspense fallback={<ThemedSuspense />}>
              <div>
                <Children />
              </div>
            </Suspense>
          </div>
        </div>
      ) : (
        <SessionNotFound sessionStatus={session?.status} />
      )}{" "}
    </div>
  );
}

export default Layout;
