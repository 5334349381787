import { gql } from "urql";
export * from "./inventories";
export * from "./reports";

export const GetStaffQuery = gql`
  query GetStaff {
    user {
      id
      email
      name
      profile_pic
    }
  }
`;

export const GetTemplateByName = gql`
  query TemplateByName($name: String = "") {
    email_templates(where: { temp_name: { _eq: $name } }) {
      message
      id
      signature
      subject
      temp_name
    }
  }
`;
export const ListAirportsQuery = gql`
  query ListAirportsQuery {
    airports(order_by: { name: asc }) {
      id
      name
      airport_code
    }
  }
`;

export const GetTemplateNames = gql`
  query GetTemplateNames {
    email_templates(order_by: { temp_name: asc }) {
      id
      temp_name
      bg_color
      border_color
    }
  }
`;
export const GetTemplateByPK = gql`
  query TemplateByPK($id: uuid = "") {
    email_templates_by_pk(id: $id) {
      message
      id
      signature
      subject
      temp_name
    }
  }
`;

export const UpdateTemplateByPK = gql`
  mutation UpdateEmailTemplate(
    $_set: email_templates_set_input = {}
    $id: uuid = ""
  ) {
    update_email_templates_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const ListUsersQuery = gql`
  query ListUsers(
    $limit: Int = 10
    $offset: Int = 0
    $where: user_bool_exp = {}
  ) {
    user(
      limit: $limit
      offset: $offset
      order_by: { created_at: asc }
      where: $where
    ) {
      name
      id
      email
      role
      status
    }
    user_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const DeleteUserByPK = gql`
  mutation DeleteUserByPK(
    $id: uuid = ""
    $description: String = ""
    $user_id: uuid = ""
  ) {
    delete_user_by_pk(id: $id) {
      id
    }
    insert_activities_one(
      object: { type: "user", description: $description, user_id: $user_id }
    ) {
      id
    }
  }
`;

export const UpdateUserByPk = gql`
  mutation UpdateUserByPK(
    $id: uuid = ""
    $_set: user_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      name
      profile_pic
    }
    insert_activities_one(
      object: { type: "user", description: $description, user_id: $user_id }
    ) {
      id
    }
  }
`;
export const AddUserMutation = gql`
  mutation AddUserMutation($user_id: uuid = "", $description: String = "") {
    insert_activities_one(
      object: { type: "user", user_id: $user_id, description: $description }
    ) {
      id
    }
  }
`;
export const signedInMutation = gql`
  mutation AddUserMutation($description: String = "", $user_id: uuid = "") {
    insert_activities(
      objects: { description: $description, type: "user", user_id: $user_id }
    ) {
      affected_rows
    }
  }
`;
export const ListAddressLocationQuery = gql`
  query ListAddressLocation(
    $where: addresses_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 10
    $where1: addresses_bool_exp = {}
  ) {
    addresses(
      distinct_on: [airline_id, airport_id, theme_park_id, car_rental_id]
      where: $where
      limit: $limit
      offset: $offset
    ) {
      location
      airline {
        address
        additional_notes
        created_at
        iata_code
        url
        name
        id
        status
      }
      airport {
        additional_notes
        address
        airport_code
        id
        name
        status
        url
      }
      theme_park {
        additional_notes
        address
        id
        name
        url
        state
      }
      car_rental {
        additional_notes
        address
        id
        name
        url
        status
      }
    }
    addresses_aggregate(
      where: $where
      distinct_on: [airline_id, airport_id, theme_park_id, car_rental_id]
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const ListAddressQuery = gql`
  query ListAddressQuery($where: addresses_bool_exp = {}) {
    addresses(where: $where, order_by: { created_at: desc }) {
      contact_person
      email
      id
      created_at
      location
      phone_number
      car_rental_id
      airport_id
      airline_id
      theme_park_id
      airport {
        id
        name
      }
      theme_park {
        id
        name
      }
      car_rental {
        id
        name
      }
      airline {
        id
        name
      }
    }
  }
`;
export const InserAddressesMutation = gql`
  mutation AddAddressMutation($objects: [addresses_insert_input!] = {}) {
    insert_addresses(objects: $objects) {
      affected_rows
    }
  }
`;
export const DeleteAddressByPK = gql`
  mutation DeleteAddressByPK($id: uuid = "") {
    delete_addresses_by_pk(id: $id) {
      id
    }
  }
`;
export const AddAddressMuatation = gql`
  mutation AddAddressMutation($object: addresses_insert_input = {}) {
    insert_addresses_one(object: $object) {
      id
    }
  }
`;
export const UpdateAddressMutation = gql`
  mutation UpdateAddressMutation(
    $id: uuid = ""
    $_set: addresses_set_input = {}
  ) {
    update_addresses_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;
export const GetEmailLogsQuery = gql`
  query GetEmailLogsQuery(
    $offset: Int = 0
    $limit: Int = 10
    $where: email_logs_bool_exp = {}
  ) {
    email_logs(
      offset: $offset
      limit: $limit
      where: $where
      order_by: { created_at: desc }
    ) {
      from
      id
      body
      subject
      to
      user {
        email
        name
        role
      }
      created_at
      report {
        first_name
        last_name
        claim_id
        id
      }
    }
    email_logs_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const GetPaymentLogsQuery = gql`
  query GetPaymentLogsQuery(
    $offset: Int = 0
    $limit: Int = 10
    $where: payment_logs_bool_exp = {}
  ) {
    payment_logs(
      offset: $offset
      limit: $limit
      where: $where
      order_by: { created_at: desc }
    ) {
      id
      created_at
      report {
        first_name
        last_name
        claim_id
      }
      user {
        name
        email
      }
      payment_method
      amount
      manually_marked_by
      created_at
    }
    payment_logs_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const GetActivityLogsQuery = gql`
  query GetactivityLogsQuery(
    $offset: Int = 0
    $limit: Int = 10
    $where: report_activities_bool_exp = {}
  ) {
    report_activities(
      offset: $offset
      limit: $limit
      where: $where
      order_by: { created_at: desc }
    ) {
      id
      created_at
      report {
        claim_id
      }
      user {
        name
        email
        role
      }
      activity_type
      description
      note
    }
    report_activities_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const OtherActivityLogsquery = gql`
  query GetotheractivityLogsQuery(
    $offset: Int = 0
    $limit: Int = 10
    $where: activities_bool_exp = {}
  ) {
    activities(
      offset: $offset
      limit: $limit
      where: $where
      order_by: { created_at: desc }
    ) {
      id
      created_at
      user {
        name
        email
        role
      }
      type
      description
      for_user {
        name
        email
      }
    }
    activities_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
export const AddMessageMutation = gql`
  mutation AddMessageMutation($object: messages_insert_input = {}) {
    insert_messages_one(object: $object) {
      id
    }
  }
`;
export const AddWhiteboardMutation = gql`
  mutation AddWhiteboardMuatation($object: white_board_insert_input = {}) {
    insert_white_board_one(object: $object) {
      id
    }
  }
`;
export const ListMessagesQuery = gql`
  query ListMessages {
    messages(order_by: { order_number: asc }) {
      added_by
      user {
        email
        name
      }
      order_number
      message
      id
      created_at
    }
    messages_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const UpdateMessageMutation = gql`
  mutation UpdateMessageMutation(
    $id: uuid = ""
    $_set: messages_set_input = {}
  ) {
    update_messages_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;
export const DeleteMessageMutation = gql`
  mutation DeleteMessageByPK($id: uuid = "") {
    delete_messages_by_pk(id: $id) {
      id
    }
  }
`;
export const ListWhiteBoardQuery = gql`
  query ListWhiteBoards {
    white_board(order_by: { order_number: desc }) {
      added_by
      user {
        email
        name
      }
      order_number
      message
      id
      created_at
    }
    white_board_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const DeleteWhiteBoardMutation = gql`
  mutation DeleteWhiteBoardByPK($id: uuid = "") {
    delete_white_board_by_pk(id: $id) {
      id
    }
  }
`;

export const UpdateWhiteBoardMutation = gql`
  mutation UpdateWhiteboardMutation(
    $id: uuid = ""
    $_set: white_board_set_input = {}
  ) {
    update_white_board_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;
export const UpdateConfigMuatation = gql`
  mutation UpdateCOnfigMutation(
    $_set: config_set_input = {}
    $user_id: uuid = ""
  ) {
    update_config_by_pk(
      pk_columns: { id: "9216a6d0-9fad-4184-8e09-f948538fad98" }
      _set: $_set
    ) {
      id
    }
    insert_activities_one(
      object: {
        type: "general"
        description: "updated the general settings"
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;

export const ListConfigQuery = gql`
  query GetConfigData {
    config_by_pk(id: "9216a6d0-9fad-4184-8e09-f948538fad98") {
      address
      company_name
      contact_person
      email
      fax
      mobile_number
      payment_method
      id
      phone_number
      website_url
      price
      paypal_api
      paypal_access_token
      paypal_client_id
      auth_login_id
      auth_client_id
      auth_signature_key
      auth_transaction_key
      pay_method
      static_mail
      payment_reminder
      move_to_in_process
    }
  }
`;
export const GetProfileQuery = gql`
  query UserById($id: String = "") {
    user_by_pk(id: { _eq: $id }) {
      id
      email
      password
      role
      profile_pic
      name
      status
    }
  }
`;
export const CheckStatusSubscription = gql`
  subscription CheckSessionStatus($id: uuid = "") {
    user_session_by_pk(id: $id) {
      status
      id
    }
  }
`;

export const UserSessionQuery = gql`
  query UserSessions($_eq: uuid = "") {
    user_session(
      where: { user_id: { _eq: $_eq } }
      order_by: { created_at: desc }
    ) {
      ip
      location
      status
      device
      created_at
      id
    }
  }
`;

export const UpdateUserSessionsMutation = gql`
  mutation UpdateSessionStatu(
    $id: uuid = ""
    $status: String = ""
    $description: String = ""
    $user_id: uuid = ""
    $for: uuid = null
  ) {
    update_user_session_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      status
    }
    insert_activities_one(
      object: {
        description: $description
        type: "user"
        user_id: $user_id
        for_user_id: $for
      }
    ) {
      id
    }
  }
`;

export const UpdateWhiteBoardManyMutation = gql`
  mutation UpdateWhiteBoard(
    $id1: uuid
    $order_number1: Int
    $id2: uuid
    $order_number2: Int
  ) {
    update_white_board_many(
      updates: [
        { where: { id: { _eq: $id1 } }, _set: { order_number: $order_number1 } }
        { where: { id: { _eq: $id2 } }, _set: { order_number: $order_number2 } }
      ]
    ) {
      affected_rows
    }
  }
`;

export const UpdateMessageManyMutation = gql`
  mutation UpdateMessage(
    $id1: uuid
    $order_number1: Int
    $id2: uuid
    $order_number2: Int
  ) {
    update_messages_many(
      updates: [
        { where: { id: { _eq: $id1 } }, _set: { order_number: $order_number1 } }
        { where: { id: { _eq: $id2 } }, _set: { order_number: $order_number2 } }
      ]
    ) {
      affected_rows
    }
  }
`;

export const GetReviewsQuery = gql`
  query GetReviews {
    reviews(order_by: { date: desc }) {
      created_at
      date
      id
      title
      rate
      report_id
      review
      show
      updated_at
      user_id
      user_name
    }
    reviews_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const AddReviewMutation = gql`
  mutation AddReviewMutation($objects: [reviews_insert_input!] = {}) {
    insert_reviews(objects: $objects) {
      affected_rows
    }
  }
`;

export const UpdateReviewMutation = gql`
  mutation UpdateReviewMutation($id: uuid = "", $_set: reviews_set_input = {}) {
    update_reviews_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const DeleteReviewMutation = gql`
  mutation DeleteReviewMutation($id: uuid = "") {
    delete_reviews_by_pk(id: $id) {
      id
    }
  }
`;
