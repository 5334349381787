import React, { useState } from "react";
import { Link } from "react-router-dom";

const SidebarMultiLevel = ({ item, role }) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  return (
    <div className="-mx-3">
      <button
        onClick={(e) => {
          e.preventDefault();
          setSubmenuOpen(!submenuOpen);
        }}
        type="button"
        className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
        aria-controls="disclosure-1"
        aria-expanded="false"
      >
        {item?.title}

        <svg
          className={`h-5 w-5 flex-none ${submenuOpen && "rotate-180"}`}
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      {/* sub menu */}
      <div
        className={`mt-2 space-y-2 ${!submenuOpen && "hidden"}`}
        id="disclosure-1"
      >
        {item?.submenu?.map((sub) => (
          <Link
            className={`${
              sub?.role === "admin" && role !== "admin" && "hidden"
            } block text-start rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-700 hover:bg-gray-50`}
            to={sub?.link}
          >
            {sub?.title}
          </Link>
        ))}
      </div>
    </div>
  );
};
export default SidebarMultiLevel;
