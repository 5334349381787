import React, { useContext, useEffect } from "react";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";
import { AuthContext } from "./context/auth_context";
import Layout from "./components/Layout";

const PrivateRoute = ({ children, ...rest }) => {
  const { auth, session } = useContext(AuthContext);
  const { loading } = auth;

  return (
    <>
      {loading ? (
        <Routes>
          <Route
            {...rest}
            render={() => {
              return <p>Loading...</p>;
            }}
          />
        </Routes>
      ) : auth?.data?.token &&
        auth?.data?.token?.split() !== "" &&
        session?.id ? (
        <Layout children={children} />
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

export default PrivateRoute;
