import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { useRef } from "react";

const MultiLevelNav = ({ item, role }) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const catMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (catMenu.current && menuOpen && !catMenu.current.contains(e.target)) {
      setMenuOpen(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  return (
    <div className="relative" ref={catMenu}>
      {/* inevntories */}
      <button
        onClick={(e) => {
          e.preventDefault();
          setMenuOpen(!menuOpen);
        }}
        type="button"
        className={`flex capitalize items-center gap-x-1 text-sm font-semibold leading-6 ${
          location?.pathname?.trim() !== "/" &&
          item?.submenu &&
          item?.submenu?.some(
            (submenuItem) =>
              submenuItem?.link &&
              submenuItem?.link?.includes(location?.pathname)
          )
            ? "text-[#0D63FF]"
            : "text-gray-900"
        }`}
        aria-expanded="false"
      >
        {item?.title}
        <svg
          className={`h-5 ${menuOpen && "rotate-180"} w-5 flex-none `}
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {/* submenu */}
      <Transition
        show={menuOpen}
        enter="transition ease-out duration-0 absolute"
        enterFrom="opacity-100 translate-y-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div
          className={`absolute z-50 ${
            !menuOpen && "hidden"
          } -left-8 top-full z-10 mt-3 w-screen max-w-xs overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5`}
          style={{
            marginLeft: "-200px",
          }}
        >
          <div className="p-4">
            {item?.submenu?.map((sub) => (
              <div
                key={sub?.title}
                className={`${
                  sub?.role === "admin" && role !== "admin" && "hidden"
                } group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50`}
              >
                <div className="flex-auto">
                  <Link
                    to={sub?.link}
                    className={`block capitalize font-semibold ${
                      sub?.link === location?.pathname
                        ? "text-[#0D63FF]"
                        : "text-gray-900"
                    }`}
                  >
                    {sub?.title}
                    <span className="absolute inset-0"></span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Transition>
    </div>
  );
};
export default MultiLevelNav;
