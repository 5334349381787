const SessionNotFound = ({ sessionStatus }) => {
  if (!sessionStatus) {
    return <div className="text-center p-10">session not found</div>;
  } else if (sessionStatus === "pending") {
    return (
      <div className="text-center p-10">
        Please contact your supervisor to approve this device
      </div>
    );
  }
};

export default SessionNotFound;
