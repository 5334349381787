import "./App.css";
import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthProvider from "./context/auth_context";
import { ToastProvider, useToasts } from "react-toast-notifications";
import PrivateRoute from "./PrivateRoute";
import routes from "./routes";
import ThemedSuspense from "./components/themed_suspense";
const Login = lazy(() => import("./pages/login"));
const ForgotPassword = lazy(() => import("./pages/forgot_password"));
function App() {
  localStorage.theme = "light";

  return (
    <AuthProvider>
      <ToastProvider autoDismiss>
        <Suspense fallback={<ThemedSuspense />}>
          <div className="App">
            <Router>
              <Routes>
                <Route path={"/login"} element={<Login />} />
                <Route path={"/forgot-password"} element={<ForgotPassword />} />

                {routes.map((route, idx) => (
                  <Route
                    key={idx}
                    path={`${route.path}`}
                    element={
                      <PrivateRoute
                        path={`${route.path}`}
                        key={idx}
                        children={route.component}
                      />
                    }
                  />
                ))}
              </Routes>
            </Router>
          </div>
        </Suspense>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;
