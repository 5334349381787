import React from "react";
import { Link } from "react-router-dom";
const Logo = () => {
  return (
    <Link to="/active-reports" className="-m-1.5 p-1.5">
      <span className="sr-only">Lost And Found</span>
      <img className="h-8 w-auto" src="/logo.svg" alt="" />
    </Link>
  );
};
export default Logo;
