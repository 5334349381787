import React, { createContext, useState, useEffect } from "react";
import { hasura_endpoint } from "../config";
import {
  createClient,
  Provider,
  subscriptionExchange,
  cacheExchange,
  fetchExchange,
  Client,
} from "urql";
import { createClient as createWSClient } from "graphql-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ loading: true, data: null });
  const [session, setSession] = useState({});

  let headers = {};

  if (auth?.data?.token) {
    headers = {
      Authorization: `Bearer ${auth?.data?.token}`,
    };
  }

  const wsClient = createWSClient({
    url: "wss://hasura.lostandfoundoffice.org/v1/graphql",
    connectionParams: {
      headers: {
        Authorization: `Bearer ${auth?.data?.token}`,
      },
    },
  });

  const client = new Client({
    url: "https://hasura.lostandfoundoffice.org/v1/graphql",
    exchanges: [
      cacheExchange,
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(request) {
          const input = { ...request, query: request.query || "" };
          return {
            subscribe(sink) {
              const unsubscribe = wsClient.subscribe(input, sink);
              return { unsubscribe };
            },
          };
        },
      }),
    ],
    requestPolicy: "network-only",
    fetch: fetch,
    fetchOptions: () => {
      if (!auth) {
        return true;
      }
      return {
        headers,
      };
    },
  });

  const setAuthData = (data) => {
    setAuth({ data: data });
  };
  const setSessionData = (data) => {
    setSession(data);
  };
  useEffect(() => {
    setAuth({
      loading: false,
      data: JSON.parse(localStorage.getItem("authData")),
    });
  }, []);
  useEffect(() => {
    setSession({
      ...JSON.parse(localStorage.getItem("sessionLF")),
    });
  }, []);
  useEffect(() => {
    localStorage.setItem("sessionLF", JSON.stringify(session));
  }, [session]);
  //This function will be executed every time component is mounted (every time the user refresh the page);

  useEffect(() => {
    localStorage.setItem("authData", JSON.stringify(auth.data));
  }, [auth]);
  // 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.
  return (
    <AuthContext.Provider
      value={{ auth, setAuthData, session, setSessionData }}
    >
      <Provider value={client}>{children}</Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
