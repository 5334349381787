import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import MultiLevelNav from "./multi_level_nav";
import SidebarMultiLevel from "./sidebar_multi_level";
import Logo from "./logo";
import { AuthContext } from "../../context/auth_context";
// import Logout from "../logout_button";
import { Banner, Button } from "flowbite-react";

function Header({}) {
  const location = useLocation();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { auth } = useContext(AuthContext);
  const items = [
    { title: "Active Reports", link: "/active-reports" },
    {
      title: "Reports",
      link: "#",
      submenu: [
        // { title: "Closed ", link: "/closed-reports" },
        // { title: "Not Found ", link: "/not-found-reports" },
        // { title: "Found ", link: "/found-reports" },
        // { title: "Refunded ", link: "/refunded-reports" },
        { title: "Closed (Not Paid) ", link: "/not-paid-closed-reports" },
      ],
    },

    {
      title: "Configure",
      link: "#",
      submenu: [
        { title: "email templates", link: "/email-templates", role: "admin" },
        { title: "address book", link: "/address-book" },
        { title: "users", link: "/users", role: "admin" },
        {
          title: "inventories",
          link: "/inventories",
        },
        {
          title: "reviews",
          link: "/reviews",
        },
      ],
    },
    { title: "Messages", link: "/messages" },
    { title: "White Board", link: "/white-board" },
    {
      title: "Logs",
      submenu: [
        { title: "email logs", link: "/email-logs" },
        { title: "payment logs", link: "/payment-logs" },
        { title: "activity logs", link: "/activity-logs" },
        { title: "logs", link: "/logs" },
      ],
    },
    {
      title: "profile",
      link: "/profile",
    },
  ];

  return (
    <Banner>
      <header className="bg-white w-full fixed z-50 top-0 justify-between capitalize ">
        <div>
          {" "}
          {/* navigation bar */}
          <nav
            className="hidden lg:flex mx-auto  items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            {/* logo */}
            <div className="flex lg:flex-1">
              <Logo />
            </div>

            <div className="hidden lg:flex lg:gap-x-12 items-center">
              {items?.map((item) => (
                <>
                  {item?.submenu ? (
                    <MultiLevelNav role={auth?.data?.role} item={item} />
                  ) : (
                    <Link
                      to={item?.link}
                      className={`text-sm capitalize font-semibold leading-6 ${
                        item?.link === location?.pathname
                          ? "text-[#0D63FF]"
                          : "text-gray-900"
                      }`}
                    >
                      {item?.title === "profile" ? (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={
                            auth?.data?.profile_pic ||
                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                          alt="profile"
                        />
                      ) : (
                        item?.title
                      )}
                    </Link>
                  )}
                </>
              ))}{" "}
            </div>
          </nav>
          {/* mobile view */}
          {sideBarOpen ? (
            <div className="lg:hidden" role="dialog" aria-modal="true">
              <div className="fixed inset-0 z-10"></div>
              <div className="fixed  inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                  <Logo />
                  {/* close button */}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSideBarOpen(false);
                    }}
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      ariaHidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                      {items?.map((item) => (
                        <>
                          {item?.submenu ? (
                            <SidebarMultiLevel
                              role={auth?.data?.role}
                              key={item?.title}
                              item={item}
                            />
                          ) : (
                            <Link
                              key={item?.title}
                              className="-mx-3 block rounded-lg px-3 text-start py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                              to={item?.link}
                            >
                              {item?.title}
                            </Link>
                          )}
                        </>
                      ))}
                      <div className="flex justify-center w-full">
                        Logout
                        {/* <Logout className={"w-full"} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // humburger menu
            <div className="flex justify-end lg:hidden">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setSideBarOpen(true);
                }}
                type="button"
                className="-m-2.5 py-10 pr-5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </header>
    </Banner>
  );
}

export default Header;
