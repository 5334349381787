import { gql } from "urql";
export const ListReportsQuery = gql`
  subscription ListReports(
    $limit: Int
    $offset: Int
    $where: reports_bool_exp = {}
  ) {
    reports(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { claim_id: desc }
    ) {
      lf_notified_pass
      id
      images
      location
      first_name
      last_name
      claim_id
      is_pass
      status
      review
      lost_date
      lost_time_of_the_day
      marked_urgent_at
      google
      netracer
      item_description
      lost_item_details
      category {
        schema
        name
      }
      report_categories {
        details
        description
        id
        images
        category {
          name
          id
          schema
        }
      }
      reports_stickies_aggregate(where: { status: { _eq: "open" } }) {
        aggregate {
          count
        }
      }
      active_user
      active_user_details {
        name
        email
      }
      user {
        id
        name
        profile_pic
      }
      category {
        name
      }
      created_at
    }
  }
`;
export const ListReportCount = gql`
  subscription ListReportsCount($where: reports_bool_exp = {}) {
    reports_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const ListClosedReportsQuery = gql`
  subscription ListClosedReports(
    $limit: Int
    $offset: Int
    $where: reports_bool_exp = {}
  ) {
    reports(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { claim_id: desc }
    ) {
      id
      active_user_details {
        name
        email
      }

      lf_notified_pass
      active_user
      status
      lost_time_of_the_day
      location
      review
      first_name
      is_pass
      last_name
      claim_id
      marked_urgent_at
      lost_date
      google
      netracer
      images
      reports_stickies_aggregate(where: { status: { _eq: "open" } }) {
        aggregate {
          count
        }
      }
      user {
        id
        name
        profile_pic
      }
      category {
        name
      }
      created_at
    }
  }
`;

export const ReportDetailsQuery = gql`
  query ReportDetailsQuery($id: uuid = "") {
    reports_by_pk(id: $id) {
      claim_id
      images
      assigned_to
      first_name
      last_name
      review
      lf_notified_pass
      lf_notified_details
      country_code
      active_user
      report_categories(order_by: { created_at: asc }) {
        category_id
        details
        description
        images
        id
        category {
          name
          id
          schema
        }
      }
      active_user_details {
        name
        email
      }
      category {
        id
        name
        schema
        disabled: is_deleted
      }
      is_pass
      generated_email
      send_sms
      phone_number
      address
      email
      city
      country
      zip
      location
      lost_time_of_the_day
      location_details
      item_description
      lost_item_details
      lost_date
      id
      reference_number
      airport {
        id
        name
      }
      state
      status
      created_at
      updated_at
      marked_urgent_at
      google
      netracer
      user {
        email
      }
    }
    config_by_pk(id: "9216a6d0-9fad-4184-8e09-f948538fad98") {
      static_mail
      price
      move_to_in_process
    }
  }
`;

export const ActivityFeedsQuery = gql`
  query ActivityFeedsQuery($id: uuid!) {
    report_activities(
      where: { report_id: { _eq: $id } }
      order_by: { created_at: desc }
    ) {
      activity_type
      description
      note
      created_at
      user_id
      id
      email_log_id
      email_log {
        body
        subject
        email_template {
          temp_name
        }
      }
      user {
        name
        email
      }
    }
  }
`;

export const AddCommentMutation = gql`
  mutation AddReportActivity($object: report_activities_insert_input = {}) {
    insert_report_activities_one(object: $object) {
      id
    }
  }
`;
export const EditCommentMutation = gql`
  mutation EditComment($id: uuid = "", $note: String = "") {
    update_report_activities_by_pk(
      pk_columns: { id: $id }
      _set: { note: $note }
    ) {
      id
    }
  }
`;
export const UpdateReportMutation = gql`
  mutation UpdateReportMutation($id: uuid = "", $_set: reports_set_input = {}) {
    update_reports_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      assigned_to
    }
  }
`;
export const UpdateReportAndActivityMutation = gql`
  mutation UpdateReportMutation(
    $id: uuid = ""
    $_set: reports_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
    $activity_type: String = ""
  ) {
    update_reports_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      assigned_to
    }
    insert_report_activities_one(
      object: {
        description: $description
        report_id: $id
        user_id: $user_id
        activity_type: $activity_type
      }
    ) {
      id
    }
  }
`;
export const ManuallyMarkPaymentMutation = gql`
  mutation UpdateManualPaymentMutation(
    $report_id: uuid = ""
    $user_id: uuid = ""
    $description: String = ""
    $amount: float8 = ""
  ) {
    insert_payment_logs_one(
      object: {
        manually_marked_by: $user_id
        payment_method: "manual"
        report_id: $report_id
        status: "paid"
        amount: $amount
      }
    ) {
      id
    }
    insert_report_activities_one(
      object: {
        activity_type: "payment_marked"
        description: $description
        report_id: $report_id
        user_id: $user_id
      }
    ) {
      id
    }
    update_reports_by_pk(
      pk_columns: { id: $report_id }
      _set: { status: "open" }
    ) {
      id
    }
  }
`;
export const ChangeStatusMutation = gql`
  mutation ChangeStatusMutation(
    $id: uuid = ""
    $_set: reports_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
  ) {
    update_reports_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      status
      lf_notified_details
    }
    insert_report_activities_one(
      object: {
        activity_type: "status_change"
        description: $description
        user_id: $user_id
        report_id: $id
      }
    ) {
      id
    }
  }
`;
export const AssignToUser = gql`
  mutation UpdateReportMutation(
    $id: uuid = ""
    $_set: reports_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
    $activity_type: String = ""
  ) {
    update_reports_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      assigned_to
    }
    insert_report_activities_one(
      object: {
        activity_type: $activity_type
        description: $description
        report_id: $id
        user_id: $user_id
      }
    ) {
      id
    }
  }
`;
export const GetCategoryByPKQuery = gql`
  query CategoryByPKQuery($id: uuid = "") {
    categories_by_pk(id: $id) {
      id
      name
      schema
    }
  }
`;

export const GetPaymentSuccessTemplate = gql`
  query GetPaymentSuccessTemplate {
    email_templates_by_pk(id: "ab8c6faa-e22e-416b-aa29-c8919fb9deda") {
      message
      id
      signature
      subject
      temp_name
    }
  }
`;
export const FetchPaidEmails = gql`
  query FetchPaidEmails {
    email_templates(
      where: { condition: { _eq: "paid" } }
      order_by: { order: asc_nulls_first }
    ) {
      bg_color
      border_color
      id
      temp_name
      label
    }
  }
`;

export const AddStickyMutation = gql`
  mutation AddSticky(
    $added_by: uuid = ""
    $report_id: uuid = ""
    $text: String = ""
    $description: String = ""
  ) {
    insert_sticky_one(
      object: { added_by: $added_by, report_id: $report_id, text: $text }
    ) {
      id
    }
    insert_report_activities_one(
      object: {
        activity_type: "sticky added"
        user_id: $added_by
        note: $text
        description: $description
        report_id: $report_id
      }
    ) {
      id
    }
  }
`;

export const GetLastStickyQuery = gql`
  query GetLastSticky($_eq: uuid = "") {
    sticky(
      limit: 1
      where: { report_id: { _eq: $_eq } }
      order_by: { created_at: desc }
    ) {
      id
      text
      status
    }
  }
`;

export const UpdateStickyMutation = gql`
  mutation UpdateStickyMutation(
    $id: uuid = ""
    $_set: sticky_set_input = {}
    $object: report_activities_insert_input = {}
  ) {
    update_sticky_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    insert_report_activities_one(object: $object) {
      id
    }
  }
`;
export const InsertReportCategoryMutation = gql`
  mutation InsertReportCategoryMutation(
    $object: report_categories_insert_input = {}
    $description: String = ""
    $user_id: uuid = ""
    $activity_type: String = ""
    $report_id: uuid = ""
  ) {
    insert_report_categories_one(object: $object) {
      id
    }
    insert_report_activities_one(
      object: {
        description: $description
        user_id: $user_id
        activity_type: $activity_type
        report_id: $report_id
      }
    ) {
      id
    }
  }
`;
export const DeleteRepotCategoryMutation = gql`
  mutation DeleteReportCategoryMutation(
    $id: uuid = ""
    $description: String = ""
    $user_id: uuid = ""
    $activity_type: String = ""
    $report_id: uuid = ""
  ) {
    delete_report_categories_by_pk(id: $id) {
      id
    }
    insert_report_activities_one(
      object: {
        description: $description
        user_id: $user_id
        activity_type: $activity_type
        report_id: $report_id
      }
    ) {
      id
    }
  }
`;
export const UpdateReportCategoryMutation = gql`
  mutation UpdateReportCategoryMuatation(
    $id: uuid = ""
    $_set: report_categories_set_input = {}
    $description: String = ""
    $user_id: uuid = ""
    $activity_type: String = ""
    $report_id: uuid = ""
  ) {
    update_report_categories_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    insert_report_activities_one(
      object: {
        description: $description
        user_id: $user_id
        activity_type: $activity_type
        report_id: $report_id
      }
    ) {
      id
    }
  }
`;
export const ListReportCategoriesQuery = gql`
  query ReportCategoriesQuery($_eq: uuid = "") {
    report_categories(
      where: { report_id: { _eq: $_eq } }
      order_by: { created_at: desc }
    ) {
      category_id
      details
      description
      id
      category {
        name
        id
        schema
      }
    }
  }
`;
